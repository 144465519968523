import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsapp, FaMap, FaEnvelope, FaPhone } from 'react-icons/fa';

const Footer = () => {
  const [deviceCount, setDeviceCount] = useState(2432);

  const incrementCountRandomly = () => {
    const randomIncrement = Math.floor(Math.random() * 10) + 1;
    setDeviceCount((prevCount) => prevCount + randomIncrement);
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      incrementCountRandomly();
    }, 3600 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const countElement = document.getElementById('device-count');
    countElement.classList.add('count-transition');

    const timeoutId = setTimeout(() => {
      countElement.classList.remove('count-transition');
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [deviceCount]);
 
  return (
    <footer className="bg-gray-100 py-24 px-5 md:px-20 shadow-md w-full relative">
      {/* Desktop layout applies from 1025px onwards */}
<div className="hidden xl:grid grid-cols-4 gap-8 container mx-auto">
  {/* REGEN - Device Count */}
  <div className="space-y-8">
    <div className='space-y-2 text-center'>
      <span 
        id='device-count' 
        className='text-6xl font-bold text-customGreen transition-transform duration-500 ease-in-out'
      >
        {deviceCount}
      </span>
      <h2 className='text-3xl font-semibold'>Trade-ins Processed</h2>
    </div>
    <p className='text-lg text-gray-600 text-center'>
      Join our community of satisfied customers and trade in your old devices for cash or store credit.
    </p>
  </div>

  {/* Pages */}
  <div className="space-y-2">
    <h6 className="text-lg font-bold font-urbanist mb-2 pl-12">Trade-In</h6>
    <ul className="space-y-2 pl-12">
      <li><Link to="/iphone" className="block font-urbanist text-gray-700 hover:text-gray-900">iPhone</Link></li>
      <li><Link to="#" className="block font-urbanist text-gray-700 hover:text-gray-900">Samsung</Link></li>
      <li><Link to="/ipad" className="block font-urbanist text-gray-700 hover:text-gray-900">iPad</Link></li>
      <li><Link to="/macbook" className="block font-urbanist text-gray-700 hover:text-gray-900">MacBook</Link></li>
      <li><Link to="/watch" className="block font-urbanist text-gray-700 hover:text-gray-900">Watch</Link></li>
      <li><Link to="#" className="block font-urbanist text-gray-700 hover:text-gray-900">Accessories</Link></li>
    </ul>
  </div>

  {/* Utility Pages */}
  <div className="space-y-2">
    <h6 className="text-lg font-bold font-urbanist mb-2">Legal</h6>
    <ul className="space-y-2">
      <li><Link to="/personal-data-policy" className="block font-urbanist text-gray-700 hover:text-gray-900">Protection of Personal Data</Link></li>
      <li><Link to="/trade-in-terms" className="block font-urbanist text-gray-700 hover:text-gray-900">Trade-In Terms & Conditions</Link></li>
      <li><Link to="/data-erasure-policy" className="block font-urbanist text-gray-700 hover:text-gray-900">Data Erasure Policy</Link></li>
    </ul>
  </div>

  {/* Contact Us */}
  <div className="space-y-4">
    <h6 className="text-lg font-bold font-urbanist mb-2">Help</h6>
    <div
      className="flex items-center mb-2 cursor-pointer"
      onClick={() => window.open('https://api.whatsapp.com/send?phone=923111005569', '_blank')}
    >
      <FaWhatsapp className="h-5 w-5 text-gray-700 mr-4" />
      <p className="text-gray-500 font-urbanist text-sm">WhatsApp Chat</p>
    </div>
    <div className="flex items-center mb-2">
      <FaMap className="h-5 w-5 text-gray-700 mr-4" />
      <p className="text-gray-500 font-urbanist text-sm">297 St 10, Sector Y, DHA Phase 3, Lahore</p>
    </div>
    <div className="flex items-center mb-2">
      <FaEnvelope className="h-5 w-5 text-gray-700 mr-4" />
      <p className="text-gray-500 font-urbanist text-sm">support@regen.pk</p>
    </div>
    <div className="flex items-center mb-2">
      <FaPhone className="h-5 w-5 text-gray-700 mr-4" />
      <p className="text-gray-500 font-urbanist text-sm">(+92) 311-1005569</p>
    </div>
  </div>

  {/* Reviews in Row Format */}
  <div className="col-span-4 mt-8">
    <h6 className="text-lg font-bold font-urbanist mb-4 text-center">What Our Customers Say</h6>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      {[
        { name: "Asad R.", rating: 5, review: "Fantastic service! Got the best value for my old iPhone." },
        { name: "Umar F.", rating: 4, review: "Quick and easy process. Will definitely use REGEN again." },
        { name: "Maham A.", rating: 5, review: "Highly recommend! Great customer support and fast payment." },
        { name: "Zahra K.", rating: 4, review: "Good experience overall. The team was very helpful!" }
      ].map((review, index) => (
        <div key={index} className="bg-gray-100 p-4 rounded-lg shadow text-center">
          <div className="flex justify-center items-center mb-2">
            <span className="text-xl font-semibold">{review.name}</span>
            <div className="ml-2 flex">
              {[...Array(5)].map((_, i) => (
                <svg
                  key={i}
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-5 w-5 ${i < review.rating ? 'text-yellow-500' : 'text-gray-300'}`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9.049 2.927a.75.75 0 011.414 0l1.513 3.06 3.382.492a.75.75 0 01.416 1.279l-2.448 2.385.578 3.372a.75.75 0 01-1.088.791L10 13.347l-3.029 1.593a.75.75 0 01-1.088-.79l.578-3.372-2.448-2.386a.75.75 0 01.416-1.279l3.382-.492 1.513-3.06z" />
                </svg>
              ))}
            </div>
          </div>
          <p className="text-gray-700">{review.review}</p>
        </div>
      ))}
    </div>
  </div>

  {/* Image and Pink CTA for desktop */}
  <div className="hidden xl:block relative col-span-4 rounded-3xl">
    <div className="relative bg-pink-100 rounded-3xl py-16 flex items-center justify-between mt-12" style={{ paddingLeft: '40%', paddingRight: '5%' }}>
      <div className="w-full flex flex-col items-start text-left space-y-4">
        <h4 className="text-4xl md:text-5xl font-semibold font-urbanist text-gray-800 leading-tight">
          Upgrade with a <br/> Certified Used Device!
        </h4>
        <div className="flex space-x-4">
          <button className="bg-black text-white rounded-full px-8 py-3 font-semibold font-urbanist shadow-md hover:bg-gray-800">
            Upgrade Your Device
          </button>
          <button className="text-black font-urbanist font-semibold flex items-center space-x-2 hover:underline">
            <span>Browse all devices</span>
            <span>→</span>
          </button>
        </div>
      </div>

      {/* Image on the left with z-index and absolute positioning */}
      <img
        loading="lazy"
        src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/tgsgjlrzkkz2khhvpt2x"
        alt="Certified Pre-Owned Device"
        className="absolute bottom-[-180px] left-[-80px] w-[33%] z-10"
      />
    </div>
  </div>
</div>


      {/* Tablet layout (1024px - 1279px) */}
<div className="hidden md:grid xl:hidden grid-cols-2 gap-8 container mx-auto">
  {/* First row: REGEN and Pages */}
  <div className="col-span-1 grid grid-cols-2 gap-4">
<div className="space-y-6">
  {/* Adding the Cloudinary image and the ADISA text */}
  <div className="space-y-2">
    <img src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/tgpqhz3mxbgzxzear9tl
" alt="REGEN Logo" className="w-1/2" />
  </div>
  <div className='space-y-2'>
    <h2 className='text-xl font-semibold'>Trade-ins Processed</h2>
    <span id='device-count' className='text-3xl font-semibold text-customGreen transition-transform duration-500 ease-in-out'>
      {deviceCount}
    </span>
  </div>
  <p className='text-lg text-gray-600'>
    Join our community of satisfied customers and trade in your old devices for cash or store credit. 
  </p>
</div>
    <div className="space-y-6 text-left">
      <h6 className="text-lg font-bold font-urbanist mb-2">Trade-In</h6>
      <ul className="space-y-2">
          <li><Link to="/iphone" className="block font-urbanist text-gray-700 hover:text-gray-900">iPhone</Link></li>
          <li><Link to="#" className="block font-urbanist text-gray-700 hover:text-gray-900">Samsung</Link></li>
          <li><Link to="/ipad" className="block font-urbanist text-gray-700 hover:text-gray-900">iPad</Link></li>
          <li><Link to="/macbook" className="block font-urbanist text-gray-700 hover:text-gray-900">MacBook</Link></li>
          <li><Link to="/watch" className="block font-urbanist text-gray-700 hover:text-gray-900">Watch</Link></li>
          <li><Link to="#" className="block font-urbanist text-gray-700 hover:text-gray-900">Accessories</Link></li>
      </ul>
    </div>
  </div>

  {/* Second row: Utility Pages and Contact Us */}
  <div className="col-span-1 grid grid-cols-2 gap-4">
    <div className="space-y-6">
      <h6 className="text-lg font-bold font-urbanist mb-2">Legal</h6>
      <ul className="space-y-2">
          <li><Link to="/personal-data-policy" className="block font-urbanist text-gray-700 hover:text-gray-900">Protection of Personal Data</Link></li>
          <li><Link to="/trade-in-terms" className="block font-urbanist text-gray-700 hover:text-gray-900">Trade-In Terms & Conditions</Link></li>
          <li><Link to="/data-erasure-policy" className="block font-urbanist text-gray-700 hover:text-gray-900">Data Erasure Policy</Link></li>
      </ul>
    </div>

    <div className="space-y-6 text-left">
      <h6 className="text-lg font-bold font-urbanist mb-2">Help</h6>
           <div
  className="flex items-center mb-2 cursor-pointer"
  onClick={() => window.open('https://api.whatsapp.com/send?phone=923111005569', '_blank')}
>
  <FaWhatsapp className="h-5 w-5 text-gray-700 mr-2" />
  <p className="text-gray-500 font-urbanist text-sm">WhatsApp Chat</p>
</div>
      <div className="flex justify-start items-center mb-2">
        <FaMap className="h-5 w-5 text-gray-700 mr-2" /> {/* Increased size */}
        <p className="text-gray-500 font-urbanist text-sm">297 St 10, Sector Y, DHA Phase 3, Lahore</p>
      </div>
      <div className="flex justify-start items-center mb-2">
        <FaEnvelope className="h-5 w-5 text-gray-700 mr-2" /> {/* Increased size */}
        <p className="text-gray-500 font-urbanist text-sm">support@regen.pk</p>
      </div>
      <div className="flex justify-start items-center mb-2">
        <FaPhone className="h-5 w-5 text-gray-700 mr-2" /> {/* Increased size */}
        <p className="text-gray-500 font-urbanist text-sm">(+92) 311-1005569</p>
      </div>
    </div>
  </div>

  {/* Third row: Pink CTA with Image for tablets */}
<div className="col-span-2 relative w-full rounded-3xl">
  <div className="bg-pink-100 rounded-3xl relative text-center flex flex-col justify-start items-end px-8 py-16 w-full" style={{ paddingLeft: '350px' }}>
    <h4 className="text-2xl md:text-3xl font-semibold font-urbanist text-gray-800 mt-0 leading-relaxed text-right">
      Upgrade with a <br/> Certified Used Device!
    </h4>
    <div className="flex flex-col items-end mt-4 space-y-4">
      <button className="bg-black text-white rounded-full px-8 py-3 font-semibold font-urbanist shadow-md hover:bg-gray-800">
        Upgrade Your Device
      </button>
      <button className="text-black font-urbanist font-semibold text-lg">Browse all devices →</button>
    </div>
  </div>

  {/* Positioned image */}
  <img
    loading='lazy'
    src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/tgsgjlrzkkz2khhvpt2x"
    alt="Certified Pre-Owned Device"
    className="absolute bottom-[-181px] left-[-90px] w-[480px] z-10"
  />
</div>
</div>




      {/* Phone layout */}
      <div className="md:hidden grid grid-cols-1 gap-8 container mx-auto">
  {/* Adjusted layout with two rows for phone layout */}
<div className="md:hidden grid grid-cols-1 gap-8 container mx-auto">
  {/* First row: REGEN and Pages */}
  <div className="grid grid-cols-2 gap-4">
<div className="space-y-6">
  {/* Adding the Cloudinary image and the ADISA text */}
  <div className="space-y-2">
    <img src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/tgpqhz3mxbgzxzear9tl
" alt="REGEN Logo" className="w-1/2 h-auto" />
  </div>
  <div className='space-y-2'>
    <h2 className='text-lg font-semibold'>Trade-ins Processed</h2>
    <span id='device-count' className='text-3xl font-semibold text-customGreen transition-transform duration-500 ease-in-out'>
      {deviceCount}
    </span>
      <p className='text-md text-gray-700'>
    Join our community of satisfied customers and trade in your old devices for cash or store credit. 
  </p>
  </div>

</div>

    <div className="space-y-6 text-left">
      <h6 className="text-lg font-bold font-urbanist mb-2">Trade-In</h6>
      <ul className="space-y-2">
          <li><Link to="/iphone" className="block font-urbanist text-gray-700 hover:text-gray-900">iPhone</Link></li>
          <li><Link to="#" className="block font-urbanist text-gray-700 hover:text-gray-900">Samsung</Link></li>
          <li><Link to="/ipad" className="block font-urbanist text-gray-700 hover:text-gray-900">iPad</Link></li>
          <li><Link to="/macbook" className="block font-urbanist text-gray-700 hover:text-gray-900">MacBook</Link></li>
          <li><Link to="/watch" className="block font-urbanist text-gray-700 hover:text-gray-900">Watch</Link></li>
          <li><Link to="#" className="block font-urbanist text-gray-700 hover:text-gray-900">Accessories</Link></li>
      </ul>
    </div>
  </div>

  {/* Second row: Utility Pages and Contact Us */}
  <div className="grid grid-cols-2 gap-4">
    <div className="space-y-6">
      <h6 className="text-lg font-bold font-urbanist mb-2">Legal</h6>
      <ul className="space-y-2">
          <li><Link to="/personal-data-policy" className="block font-urbanist text-gray-700 hover:text-gray-900">Protection of Personal Data</Link></li>
          <li><Link to="/trade-in-terms" className="block font-urbanist text-gray-700 hover:text-gray-900">Trade-In Terms & Conditions</Link></li>
          <li><Link to="/data-erasure-policy" className="block font-urbanist text-gray-700 hover:text-gray-900">Data Erasure Policy</Link></li>
      </ul>
    </div>

    <div className="space-y-6 text-left">
      <h6 className="text-lg font-bold font-urbanist mb-2">Help</h6>
                 <div
  className="flex items-center mb-2 cursor-pointer"
  onClick={() => window.open('https://api.whatsapp.com/send?phone=923111005569', '_blank')}
>
  <FaWhatsapp className="h-5 w-5 text-gray-700 mr-2" />
  <p className="text-gray-500 font-urbanist text-sm">WhatsApp Chat</p>
</div>
      <div className="flex items-center mb-2">
        <FaMap className="h-5 w-5 text-gray-700 mr-2" />
        <p className="text-gray-500 font-urbanist text-sm">297 St 10, Sector Y, DHA Phase 3, Lahore</p>
      </div>
      <div className="flex items-center mb-2">
        <FaEnvelope className="h-5 w-5 text-gray-700 mr-2" />
        <p className="text-gray-500 font-urbanist text-sm">support@regen.pk</p>
      </div>
      <div className="flex items-center mb-2">
        <FaPhone className="h-5 w-5 text-gray-700 mr-2" />
        <p className="text-gray-500 font-urbanist text-sm">(+92) 311-1005569</p>
      </div>
    </div>
  </div>
  </div>


  {/* Third row: Pink CTA with Image for phones */}
<div className="col-span-1 relative rounded-3xl">
  <div className="bg-pink-100 rounded-3xl relative text-center flex flex-col justify-start items-center h-full pb-80">
    <h4 className="text-2xl md:text-3xl font-semibold font-urbanist text-gray-800 mt-5">
      Upgrade with a <br /> Certified Used Device!
    </h4>
    <div className="flex flex-col items-center mt-4">
      <button className="bg-black text-white rounded-full px-8 py-3 font-semibold font-urbanist shadow-md hover:bg-gray-800 mb-5">
        Upgrade Your Device
      </button>
      <button className="text-black font-urbanist font-semibold text-lg">Browse all devices →</button>
    </div>
  </div>



    {/* Positioned image */}
    <img
      loading='lazy'
      src="https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/tgsgjlrzkkz2khhvpt2x"
      alt="Certified Pre-Owned Device"
      className="absolute bottom-[-96px] left-[-30px] w-[380px] z-1"
    />
  </div>
  </div>

<div className="hidden md:block text-center mt-16">
  <p className="text-gray-500 font-urbanist text-sm">Copyright © REGEN | 2024</p>
</div>
  
    </footer>
  );
};

export default Footer;
