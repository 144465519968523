import React from 'react';
import { Link } from 'react-router-dom';
import ResourcePosts from './ResourceData'; // Import your Resource post data

const ResourceListPage = () => {
  return (
    <div className="container max-w-6xl mx-auto px-5 py-10 mt-12">
      <h1 className="text-4xl font-bold mb-10 text-center">Resources</h1>
      <p className="text-center mb-10 text-gray-700 text-xl">
        Explore our latest resources to stay updated on the newest trends.
      </p>
      <div className="space-y-10">
        {ResourcePosts.map(post => (
          <div 
            key={post.id} 
            className="flex flex-col lg:flex-row lg:items-center justify-center lg:space-x-10 space-y-5 lg:space-y-0"
          >
            {/* Image on the left, adjusted size on desktop */}
            <img 
              src={post.image} 
              alt={post.title} 
              className="w-full lg:w-1/2 h-auto rounded-lg shadow-lg object-cover" 
            />
            <div className="flex-1 lg:text-left text-center">
              <p className="text-sm text-gray-500 mb-2">{new Date(post.date).toLocaleDateString()}</p>
              <h2 className="text-2xl font-bold">
                <Link to={`/resource/${post.id}`} className="text-gray-900 hover:underline">
                  {post.title}
                </Link>
              </h2>
              <p className="mt-2 text-gray-600">{post.description}</p>
              <Link to={`/resource/${post.id}`} className="text-gray-900 mt-4 inline-block underline">
                Read more
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResourceListPage;
