const resourcePosts = [
  {
    id: 1,
    title: "How to sell your iPhone to REGEN",
    date: "2023-06-12",
    description: "REGEN introduced online device evaluation form on 1st of February, 2022. Here's a step-by-step guide on how to sell your iPhone to REGEN.",
    image: "https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/clj9ofyowz1ud7mtm8gq",
    fullText: [
      { type: "paragraph", content: "REGEN introduced online device evaluation form on 1st of February, 2022. This was the first time a company offered exact value through online evaluation in Pakistan. Since then thousands of iPhones have been submitted and we've bought a lot of them." },
      { type: "paragraph", content: "<strong>Here's the step-by-step guide:</strong>" },
      { type: "list", style: "ordered", items: [
        { content: "<strong>Step 1:</strong> Fill online evaluation form." },
        { content: "<strong>Step 2:</strong> The appointment." },
      ]},
      { type: "paragraph", content: "<strong>Step 1:</strong> Fill online evaluation form. Click/Tap 'Sell iPhone', answer all the questions about your iPhone." },
      { type: "paragraph", content: "<strong>Step 2:</strong> The appointment. Once you've submitted the form, you'll get a WhatsApp message. If you live in Lahore, answer 'Yes' to the WhatsApp message. One of our representatives will get in touch with you with further instructions." }
    ]
  },
    {
    id: 2,
    title: "Submitted iPhone, what's next?",
    date: "2023-06-12",
    description: "If you entered your correct WhatsApp number, you'll shortly receive a message from REGEN. Here are the steps to follow after submission.",
    image: "https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/vcfswdytdi9dpr8f8wgd",
    fullText: [
      { type: "paragraph", content: "If you entered your correct WhatsApp number, you'll shortly receive a WhatsApp message from REGEN. The message should include the model of the device, its online evaluation, and a 'Yes' or 'No' question asking if you live in Lahore. Please tap on 'Yes' or 'No' to proceed." },
      { type: "paragraph", content: "<strong>Next steps if you live in Lahore:</strong>" },
      { type: "list", style: "ordered", items: [
        { content: "<strong>Step 1:</strong> Setup the meeting." },
        { content: "<strong>Step 2:</strong> Prepare your phone for sale." },
      ]},
      { type: "paragraph", content: "<strong>Setting up the meeting:</strong> Our team will carefully go through your submission and either refuse to buy your iPhone or schedule a meeting with you in person." },
      { type: "paragraph", content: "<strong>Preparing the device for sale:</strong> Once your meeting has been scheduled, it's time for you to prepare your iPhone for sale. Here are the steps you can follow to make the process easier:" },
      { type: "list", style: "unordered", items: [
        { content: "Back up your phone with iCloud, Mac, or Windows." },
        { content: "Look for the accessories/box and keep them handy." },
        { content: "Keep your iPhone at least 50% charged." },
        { content: "Remove the screen protector." },
      ]},
      { type: "paragraph", content: "<strong>Next steps if you live outside of Lahore:</strong>" },
      { type: "list", style: "ordered", items: [
        { content: "<strong>Step 1:</strong> Send your device to us." },
        { content: "<strong>Step 2:</strong> Prepare your device for shipping." }
      ]},
      { type: "paragraph", content: "<strong>Send the device to us:</strong> If you live outside of Lahore, the only way we can purchase your iPhone is if you send it to us." },
      { type: "paragraph", content: "<strong>Preparing the device for shipping:</strong> Once you receive the go-ahead from our support team to ship your iPhone, please follow these steps:" },
      { type: "list", style: "unordered", items: [
        { content: "Back up your phone with iCloud, Mac, or Windows." },
        { content: "Reset your phone by erasing all data and settings." },
        { content: "Password protect your phone. Our team will request the passcode when we receive it." },
        { content: "Pack the phone and accessories (if available) very carefully." },
        { content: "Share the courier company's slip with our support team." }
      ]}
    ]
  },
  {
    id: 3,
    title: "Submitted iPad, what's next?",
    date: "2023-06-12",
    description: "If you entered your correct WhatsApp number, you'll shortly receive a message from REGEN. Here are the steps to follow after submission.",
    image: "https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/trvvubl4iqkpfajnp3ri",
    fullText: [
      { type: "paragraph", content: "If you entered your correct WhatsApp number, you'll shortly receive a WhatsApp message from REGEN. The message should include the model of the device, its online evaluation, and a 'Yes' or 'No' question asking if you live in Lahore. Please tap on 'Yes' or 'No' to proceed." },
      { type: "paragraph", content: "<strong>Next steps if you live in Lahore:</strong>" },
      { type: "list", style: "ordered", items: [
        { content: "<strong>Step 1:</strong> Setup the meeting." },
        { content: "<strong>Step 2:</strong> Prepare your iPad for sale." },
      ]},
      { type: "paragraph", content: "<strong>Setting up the meeting:</strong> Our team will carefully go through your submission and either refuse to buy your iPad or schedule a meeting with you in person." },
      { type: "paragraph", content: "<strong>Preparing the device for sale:</strong> Once your meeting has been scheduled, it's time for you to prepare your iPad for sale. Here are the steps you can follow to make the process easier:" },
      { type: "list", style: "unordered", items: [
        { content: "Back up your iPad with iCloud or a Computer." },
        { content: "Look for the accessories/box and keep them handy." },
        { content: "Keep your iPad at least 50% charged." },
        { content: "Remove the screen protector or any skins." }
      ]},
      { type: "paragraph", content: "<strong>Next steps if you live outside of Lahore:</strong>" },
      { type: "list", style: "ordered", items: [
        { content: "<strong>Step 1:</strong> Send your device to us." },
        { content: "<strong>Step 2:</strong> Prepare your device for shipping." }
      ]},
      { type: "paragraph", content: "<strong>Send the device to us:</strong> If you live outside of Lahore, the only way we can purchase your iPad is if you send it to us." },
      { type: "paragraph", content: "<strong>Preparing the device for shipping:</strong> Once you receive the go-ahead from our support team to ship your iPad, please follow these steps:" },
      { type: "list", style: "unordered", items: [
        { content: "Back up your iPad with iCloud or a Computer." },
        { content: "Reset your iPad by erasing all the data and settings." },
        { content: "Password protect your iPad. Our team will request the passcode when we receive it." },
        { content: "Pack the iPad and accessories (if available) very carefully." },
        { content: "Share the courier company's slip with our support team." }
      ]}
    ]
  },
  {
    id: 4,
    title: "Submitted Mac, what's next?",
    date: "2023-06-12",
    description: "If you entered your correct WhatsApp number, you'll shortly receive a message from REGEN. Here are the steps to follow after submission.",
    image: "https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/nekrh8dtc9vx3ebt85si",
    fullText: [
      { type: "paragraph", content: "If you entered your correct WhatsApp number, you'll shortly receive a WhatsApp message from REGEN. The message should include the model of the device, its online evaluation, and a 'Yes' or 'No' question asking if you live in Lahore. Please tap on 'Yes' or 'No' to proceed." },
      { type: "paragraph", content: "<strong>Next steps if you live in Lahore:</strong>" },
      { type: "list", style: "ordered", items: [
        { content: "<strong>Step 1:</strong> Setup the meeting." },
        { content: "<strong>Step 2:</strong> Prepare your Mac for sale." },
      ]},
      { type: "paragraph", content: "<strong>Setting up the meeting:</strong> Our team will carefully go through your submission and either refuse to buy your Mac or schedule a meeting with you in person." },
      { type: "paragraph", content: "<strong>Preparing the device for sale:</strong> Once your meeting has been scheduled, it's time for you to prepare your Mac for sale. Here are the steps you can follow to make the process easier:" },
      { type: "list", style: "unordered", items: [
        { content: "Back up your Mac with iCloud or External storage." },
        { content: "Look for the accessories/box and keep them handy." },
        { content: "In case of a MacBook, please keep it at least 50% charged." },
        { content: "Remove the screen protector or any skins." },
      ]},
      { type: "paragraph", content: "<strong>Next steps if you live outside of Lahore:</strong>" },
      { type: "list", style: "ordered", items: [
        { content: "<strong>Step 1:</strong> Send your device to us." },
        { content: "<strong>Step 2:</strong> Prepare your device for shipping." }
      ]},
      { type: "paragraph", content: "<strong>Send the device to us:</strong> If you live outside of Lahore, the only way we can purchase your Mac is if you send it to us." },
      { type: "paragraph", content: "<strong>Preparing the device for shipping:</strong> Once you receive the go-ahead from our support team to ship your Mac, please follow these steps:" },
      { type: "list", style: "unordered", items: [
        { content: "Back up your Mac." },
        { content: "Reset your Mac by erasing all content and settings." },
        { content: "Password protect your Mac. Our team will request the passcode when we receive it." },
        { content: "Pack the Mac and accessories (if available) very carefully." },
        { content: "Share the courier company's slip with our support team." }
      ]}
    ]
  },
    {
    id: 5,
    title: "Submitted Apple Watch, what's next?",
    date: "2023-06-12",
    description: "If you entered your correct WhatsApp number, you'll shortly receive a message from REGEN. Here are the steps to follow after submission.",
    image: "https://res.cloudinary.com/dl1kjmaoq/image/upload/f_auto,q_auto/v1/static/i2vj0owdezaluim9d1ih",
    fullText: [
      { type: "paragraph", content: "If you entered your correct WhatsApp number, you'll shortly receive a WhatsApp message from REGEN. The message should include the model of the device, its online evaluation, and a 'Yes' or 'No' question asking if you live in Lahore. Please tap on 'Yes' or 'No' to proceed." },
      { type: "paragraph", content: "<strong>Next steps if you live in Lahore:</strong>" },
      { type: "list", style: "ordered", items: [
        { content: "<strong>Step 1:</strong> Setup the meeting." },
        { content: "<strong>Step 2:</strong> Prepare your Apple Watch for sale." },
      ]},
      { type: "paragraph", content: "<strong>Setting up the meeting:</strong> Our team will carefully go through your submission and either refuse to buy your Apple Watch or schedule a meeting with you in person." },
      { type: "paragraph", content: "<strong>Preparing the device for sale:</strong> Once your meeting has been scheduled, it's time for you to prepare your Apple Watch for sale. Here are the steps you can follow to make the process easier:" },
      { type: "list", style: "unordered", items: [
        { content: "Back up your Apple Watch with your iPhone." },
        { content: "Look for the accessories/box and keep them handy." },
        { content: "Keep your Apple Watch at least 50% charged." },
        { content: "Remove the screen protector or any skins." },
      ]},
      { type: "paragraph", content: "<strong>Next steps if you live outside of Lahore:</strong>" },
      { type: "list", style: "ordered", items: [
        { content: "<strong>Step 1:</strong> Send your device to us." },
        { content: "<strong>Step 2:</strong> Prepare your device for shipping." }
      ]},
      { type: "paragraph", content: "<strong>Send the device to us:</strong> If you live outside of Lahore, the only way we can purchase your Apple Watch is if you send it to us." },
      { type: "paragraph", content: "<strong>Preparing the device for shipping:</strong> Once you receive the go-ahead from our support team to ship your watch, please follow these steps:" },
      { type: "list", style: "unordered", items: [
        { content: "Back up your Apple Watch with iPhone." },
        { content: "Reset your watch by erasing all the data and settings." },
        { content: "Password protect your Apple Watch. Our team will request the passcode when we receive it." },
        { content: "Pack the watch and accessories (if available) very carefully." },
        { content: "Share the courier company's slip with our support team." }
      ]}
    ]
  },
];

export default resourcePosts;
