import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Header from './components/Header';
import ScrollToTop from './components/ScrollToTop';
import { AnimatePresence, motion } from 'framer-motion';
import ResourceListPage from './components/ResourceListPage';
import ResourceDetailPage from './components/ResourceDetailPage';

// Lazy load components
const IPhone = lazy(() => import('./components/iPhone'));
const Samsung = lazy(() => import('./components/Samsung'));
const IPad = lazy(() => import('./components/iPad'));
const Home = lazy(() => import('./components/Home'));
const IPhoneDetails = lazy(() => import('./components/iPhoneDetails'));
const IPadDetails = lazy(() => import('./components/iPadDetails'));
const SamsungDetails = lazy(() => import('./components/SamsungDetails'));
const MacBook = lazy(() => import('./components/Macbook'));
const MacBookDetails = lazy(() => import('./components/MacbookDetails'));
const Watch = lazy(() => import('./components/Watch'));
const WatchDetails = lazy(() => import('./components/WatchDetails'));
const DataErasurePolicy = lazy(() => import('./components/DataErasurePolicy'));
const TradeInTerms = lazy(() => import('./components/TradeInTerms'));
const PersonalDataPolicy = lazy(() => import('./components/PersonalDataPolicy'));

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      // Simulate data loading
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <Navbar />
      <main className="flex-grow mt-5" style={{ minHeight: 'calc(100vh - 200px)' }}>
        {isLoading ? (
          <div className="flex-grow flex items-center justify-center bg-white">
            <div className="loader">Loading...</div>
          </div>
        ) : (
          <AnimatePresence mode="wait">
            <Suspense fallback={<div className="loader">Loading component...</div>}>
              <Routes location={location} key={location.pathname}>
                              <Route
                  path="/data-erasure-policy"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <DataErasurePolicy />
                    </motion.div>
                  }
                />
                                <Route
                  path="/personal-data-policy"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <PersonalDataPolicy />
                    </motion.div>
                  }
                />
                                <Route
                  path="/trade-in-terms"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <TradeInTerms />
                    </motion.div>
                  }
                />
                <Route
                  path="/"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Home />
                    </motion.div>
                  }
                />
                <Route
                  path="/iphone"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <IPhone />
                    </motion.div>
                  }
                />
                <Route
                  path="/ipad"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <IPad />
                    </motion.div>
                  }
                />
                <Route
                  path="/samsung"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Samsung />
                    </motion.div>
                  }
                />
                <Route
                  path="/macbook"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <MacBook />
                    </motion.div>
                  }
                />
                <Route
                  path="/watch"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Watch />
                    </motion.div>
                  }
                />
                <Route
                  path="/product/iphone"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <IPhoneDetails />
                    </motion.div>
                  }
                />
                <Route
                  path="/product/ipad"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <IPadDetails />
                    </motion.div>
                  }
                />
                <Route
                  path="/product/samsung"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <SamsungDetails />
                    </motion.div>
                  }
                />
                <Route
                  path="/product/macbook"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <MacBookDetails />
                    </motion.div>
                  }
                />
                <Route
                  path="/product/watch"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <WatchDetails />
                    </motion.div>
                  }
                />
                                <Route
                  path="/resources"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <ResourceListPage />
                    </motion.div>
                  }
                />
                                                <Route
                  path="/resource/:id"
                  element={
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <ResourceDetailPage />
                    </motion.div>
                  }
                />
              </Routes>
            </Suspense>
          </AnimatePresence>
        )}
      </main>
      <Footer />
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  );
}

export default AppWithRouter;
