import React from 'react';
import { useParams, Link } from 'react-router-dom';
import ResourcePosts from './ResourceData';

const ResourceDetailPage = () => {
  const { id } = useParams();
  const post = ResourcePosts.find(post => post.id === parseInt(id));
  const relatedPosts = ResourcePosts.filter(p => p.id !== post.id).slice(0, 3); // Select 3 related articles

  if (!post) {
    return <div>Resource not found</div>;
  }

  return (
    <div className="container max-w-4xl mx-auto px-5 py-10 mt-12">
      {/* Main Image */}
      <img src={post.image} alt={post.title} className="w-full h-60 md:h-96 object-cover mb-5 rounded-lg shadow-lg" />

      {/* Resource Title */}
      <h1 className="text-4xl font-bold mb-5 text-center md:text-left">{post.title}</h1>

      {/* Resource Content */}
      <div className="prose lg:prose-xl text-justify">
        {post.fullText.map((section, index) => {
          if (section.type === 'paragraph') {
            return <p key={index} dangerouslySetInnerHTML={{ __html: section.content }} />;
          } else if (section.type === 'list') {
            if (section.style === 'ordered') {
              return (
                <ol key={index} className="list-decimal ml-5">
                  {section.items.map((item, i) => (
                    <li key={i} dangerouslySetInnerHTML={{ __html: item.content }} />
                  ))}
                </ol>
              );
            } else if (section.style === 'unordered') {
              return (
                <ul key={index} className="list-disc ml-5">
                  {section.items.map((item, i) => (
                    <li key={i} dangerouslySetInnerHTML={{ __html: item.content }} />
                  ))}
                </ul>
              );
            }
          }
          return null;
        })}
      </div>

      {/* Divider Line */}
      <hr className="my-10" />

      {/* Related Articles */}
      <h2 className="text-2xl md:text-3xl font-bold mb-5 text-center md:text-center">Related Articles</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        {relatedPosts.map(related => (
          <div key={related.id} className="flex flex-col items-center md:items-start">
            <img src={related.image} alt={related.title} className="w-full h-40 object-cover mb-3 rounded-lg shadow-lg " />
            <p className="text-sm text-gray-500">{new Date(related.date).toLocaleDateString()}</p>
            <h3 className="text-lg font-bold text-center md:text-left">
              <Link to={`/resource/${related.id}`} className="text-gray-700 hover:underline">
                {related.title}
              </Link>
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResourceDetailPage;
